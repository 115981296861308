import styled from '@emotion/styled'
import React, { ButtonHTMLAttributes, HTMLAttributes, ReactNode } from 'react'
// @ts-ignore
import buttonDecoration from '@/images/button-decoration.svg'
import { css, useTheme } from '@emotion/react'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button = styled.button`
  --x: 0.8rem;
  --y: 0.8rem;

  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 21rem;
  height: 5.5rem;
  font-family: ${({ theme }) => theme.fonts.roboto};
  font-size: ${({ theme }) => theme.fontSizes.body2};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  letter-spacing: ${({ theme }) => theme.letterSpacings.normal};
  transition: transform 0.3s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    z-index: -1;
  }

  &::before {
    background: url(${buttonDecoration}) left / 100%;
    transform: translate(var(--x), var(--y));
    transition: transform 0.3s;
  }

  &::after {
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 9999px;
    border: solid 1px;
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    &:hover {
      transform: translate(var(--x), var(--y));

      &::before {
        transform: translate(0, 0);
      }
    }
  }
`

export const SimpleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.8rem 4rem;
  font-size: ${({ theme }) => theme.fontSizes.body0};
  background-color: ${({ theme }) => theme.colors.gray.lighter};
  border-radius: 9999px;

  &.orange {
    padding: 1.2rem 4rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.orange.main};
  }

  &.blue {
    padding: 1.2rem 4rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.blue.main};
  }
`

export function DownloadButton({
  className,
  href,
  children,
  mt = 0,
}: {
  className?: string
  href?: string
  children?: ReactNode
  mt?: number
}) {
  const theme = useTheme()

  return (
    <a
      href={href}
      download
      target="_blank"
      rel="noopener noreferrer"
      css={css`
        display: block;
        margin-top: ${String(mt)}rem;
      `}
    >
      <SimpleButton
        className={className}
        css={css`
          margin: 0 auto;
          max-width: 65.4rem;

          @media ${theme.breakpoints.md} {
            max-width: unset;
          }

          span:not(.icon-link) {
            order: 1;
            display: inline-block;
            width: 100%;
            font-size: ${theme.fontSizes.body3};
            margin-top: -0.4rem;

            @media ${theme.breakpoints.md} {
              width: unset;
              order: unset;
              margin-top: 0.3rem;
              margin-left: 0.8rem;
            }
          }
        `}
      >
        {children}
        <span
          className="icon-link"
          css={css`
            margin-left: 0.8rem;
          `}
        />
      </SimpleButton>
    </a>
  )
}
